import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import '../App.scss'
import { useHttp } from '../hooks/http.hook'
import Table from './components/Table/Table'
import config from '../config.json'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';


const HomePage = () => {
    const { request, loading } = useHttp()
    const [partners, setPartners] = React.useState([])
    const [open, setOpen] = useState(false);
    const [partnerName, setPartnerName] = useState("");
    const [endpoint, setEndpoint] = useState("");
    const [showIsActive, setShowIsActive] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handlePartnerNameChange = (event) => {
        setPartnerName(event.target.value);
    }

    const handleEndpointChange = (event) => {
        setEndpoint(event.target.value);
    }

    const addPartner = async (name, endpoint_name) => {
        try {
            const data = await request(`/api/main/add/partner`, 'POST', {
                name, endpoint_name
            })
            console.log(data)
            getPartersList()
        } catch (e) {
            console.log(e)
        }
    }

    const getPartersList = useCallback(() => {
        request(`/api/main/get-partners`)
            .then(el => {
                const newP = el.partners
                    .filter(partner => partner.is_active)
                    .map(el => {
                        el.offers = <Link className='btn btnLink' to={`/offers/${el.id}`}>offers</Link>
                        el.delete = <IconButton aria-label="delete" onClick={() => handleDelete(el.id)}>
                            <DeleteIcon />
                        </IconButton>
                        return el
                    })
                setPartners(newP)
            })
    }, [])

    const handleDelete = async (id) => {
        try {
            const response = await request(`/api/main/delete/partner/${id}`, 'DELETE');
            console.log(response.data);
            getPartersList()
            // Тут вы можете добавить код для обновления списка партнеров на странице
        } catch (error) {
            console.error(`Ошибка удаления партнера: ${error}`);
        }
    };

    const test = async () => {
        try {
            const data = await request(`/api/side/new_partner`, 'POST',
                {
                    "ai": "ask from your trackbox partner",
                    "ci": "1",
                    "gi": "ask from your trackbox partner",
                    "userip": "29.249.50.83",
                    "firstname": "Gnanapiasam",
                    "lastname": "Johnsasdhan",
                    "email": "tesfaf321@gmail.com",
                    "password": "Aa12345!",
                    "phone": "4407012259886",
                    "so": "funnelname",
                    "sub": "FreeParam",
                    "MPC_1": "FreeParam",
                    "MPC_2": "FreeParam",
                    "MPC_3": "FreeParam",
                    "MPC_4": "FreeParam",
                    "MPC_5": "FreeParam",
                    "MPC_6": "FreeParam",
                    "MPC_7": "FreeParam",
                    "MPC_8": "FreeParam",
                    "MPC_9": "FreeParam",
                    "MPC_10": "FreeParam"
                },
                {
                    'x-trackbox-username': 'username',
                    'x-trackbox-password': 'password',
                    'x-api-key': '2643889w34df345676ssdas323tgc738'
                }
            )
        } catch (e) {
            console.log(e)
        }
    }



    React.useEffect(() => {
        getPartersList()
    }, [])

    const handleActiveChange = async (id, isActive) => {
        try {
            const response = await request(`/api/main/update/partner/active/${id}`, 'PUT', { is_active: isActive });
            console.log(response.data);
            getPartersList()
        } catch (error) {
            console.error(`Ошибка обновления статуса партнера: ${error}`);
        }
    };

    const filteredData = showIsActive ? partners
        .map(el => {
            return {
                active: <Switch checked={el.is_active} onChange={() => handleActiveChange(el.id, !el.is_active)} />,
                id: el.id,
                name: el.name,
                endpoint_name: el.endpoint_name,
                offers: el.offers,
                delete: el.delete
            }
        }) : partners
            .filter(item => item.is_active)
            .map(el => {
                return {
                    active: <Switch checked={el.is_active} onChange={() => handleActiveChange(el.id, !el.is_active)} />,
                    id: el.id,
                    name: el.name,
                    endpoint_name: el.endpoint_name,
                    offers: el.offers,
                    delete: el.delete
                }
            })

    return <div>
        <div className='card'>
            <h2>Partners</h2>
            <div>
                <Button variant="outlined" onClick={handleClickOpen}>
                    Add Partner
                </Button>

                <FormControlLabel
                    sx={{ marginLeft: '20px' }}
                    control={
                        <Switch checked={showIsActive} onChange={() => setShowIsActive(!showIsActive)} />
                    }
                    label="Show disabled"
                />

                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Add Partner</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Partner Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={partnerName}
                            onChange={handlePartnerNameChange}
                        />
                        <TextField
                            margin="dense"
                            id="endpoint_name"
                            label="Endpoint"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={endpoint}
                            onChange={handleEndpointChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={() => {
                            handleClose();
                            addPartner(partnerName, endpoint); // вызов функции addPartner, где вы будете обрабатывать добавление партнера
                        }}>Add</Button>
                    </DialogActions>
                </Dialog>
            </div>
            {
                !loading && <>
                    {!!partners.length && <Table data={filteredData} />}
                </>
            }
        </div>
    </div>
}

export default HomePage